/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import { Contract, ContractFactory, Overrides } from "@ethersproject/contracts";

import type { Identification } from "../Identification";

export class Identification__factory extends ContractFactory {
  constructor(signer?: Signer) {
    super(_abi, _bytecode, signer);
  }

  deploy(overrides?: Overrides): Promise<Identification> {
    return super.deploy(overrides || {}) as Promise<Identification>;
  }
  getDeployTransaction(overrides?: Overrides): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): Identification {
    return super.attach(address) as Identification;
  }
  connect(signer: Signer): Identification__factory {
    return super.connect(signer) as Identification__factory;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Identification {
    return new Contract(address, _abi, signerOrProvider) as Identification;
  }
}

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    name: "Registration",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
    ],
    name: "register",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "registrations",
    outputs: [
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "timestamp",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b50610154806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c8063942e6bcf1461003b578063e1fa8e841461007a575b600080fd5b6100616004803603602081101561005157600080fd5b50356001600160a01b0316610099565b6040805192835260208301919091528051918290030190f35b6100976004803603602081101561009057600080fd5b50356100b2565b005b6000602081905290815260409020805460019091015482565b604080518082018252828152426020808301828152336000818152808452869020945185559051600190940193909355835185815290810191909152825191927f365c8f0eae5b0ae25f511df9c6e1a6853020a8020c5d6b2c7557c797e81ae16192918290030190a25056fea264697066735822122076803e35e689c73a82020333cdd3e3ee9c3236fe5b45300a02c861a66adf902764736f6c63430007060033";
