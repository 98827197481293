/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { GyroPriceOracle } from "../GyroPriceOracle";

export class GyroPriceOracle__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GyroPriceOracle {
    return new Contract(address, _abi, signerOrProvider) as GyroPriceOracle;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_dollarValueIn",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_inflowHistory",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_nav",
        type: "uint256",
      },
    ],
    name: "getAmountToMint",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_dollarValueOut",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_outflowHistory",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_nav",
        type: "uint256",
      },
    ],
    name: "getAmountToRedeem",
    outputs: [
      {
        internalType: "uint256",
        name: "_gyroAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_bPoolAddress",
        type: "address",
      },
      {
        internalType: "uint256[]",
        name: "_underlyingPrices",
        type: "uint256[]",
      },
    ],
    name: "getBPTPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "_bptPrice",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
