/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from "ethers";
import { Provider } from "@ethersproject/providers";

import type { GyroRouter } from "../GyroRouter";

export class GyroRouter__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): GyroRouter {
    return new Contract(address, _abi, signerOrProvider) as GyroRouter;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_tokensIn",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "_amountsIn",
        type: "uint256[]",
      },
    ],
    name: "deposit",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_tokensOut",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "_amountsOut",
        type: "uint256[]",
      },
    ],
    name: "withdraw",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];
